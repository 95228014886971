import { useRouter } from 'next/router'
import { useEffect } from 'react'
import qs from 'qs'
import { PaginationState, SortingState, Updater, functionalUpdate } from '@tanstack/react-table'
import { DEFAULT_PAGINATION_LIMIT } from '@constant/index'
import { addPageFilters, getUserPref, setUserPref } from '@hook/common/useUserPref'
import { UserPrefKey } from '@type/usePref'
import { TableState } from '../types'

interface UseTableParams {
  tableId?: string
  tableState?: TableState
  onTableStateChange?: (newState: TableState) => void
  paginationType?: 'offset' | 'cursor'
  cursors?: {
    endCursor?: string
    startCursor?: string
  }
  defaultPageSize?: number
}

export const useTableParams = ({
  tableId,
  tableState,
  onTableStateChange,
  paginationType,
  cursors,
  defaultPageSize = DEFAULT_PAGINATION_LIMIT,
}: UseTableParams) => {
  const router = useRouter()
  const prefix = tableId ? `${tableId}_` : ''

  const perfKey = `${UserPrefKey.TablePageLimit}${tableId ? `-${tableId}` : ''}-${router.pathname}`

  const globalSearch = (tableState?.search || router.query[`${prefix}search`]) as string

  const pagination = (tableState?.pagination ||
    qs.parse(router.query[`${prefix}pagination`] as string, {
      allowDots: true,
      parseArrays: true,
      comma: true,
    }) || {
      page: 1,
      limit: defaultPageSize,
    }) as TableState['pagination']

  const sorting = (tableState?.sorting ||
    (router.query[`${prefix}sorting`] &&
      Object.values(
        qs.parse(router.query[`${prefix}sorting`] as string, {
          allowDots: true,
          parseArrays: true,
          comma: true,
        }),
      )) ||
    []) as TableState['sorting']

  useEffect(() => {
    const prevState = getUserPref(perfKey)
    if (prevState)
      addPageFilters({
        [`${prefix}pagination`]: qs.stringify({
          page: 1,
          limit: prevState,
        }),
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onPaginationChange = (updater: Updater<PaginationState>) => {
    const value = functionalUpdate(updater, {
      pageIndex: (pagination.page || 1) - 1,
      pageSize: pagination.limit || defaultPageSize,
    })

    setUserPref(perfKey, value.pageSize)

    if (!tableState) {
      if (paginationType === 'cursor') {
        const newCursors =
          value.pageIndex < Number(pagination.page - 1)
            ? { before: cursors?.startCursor }
            : { after: cursors?.endCursor }

        if (value.pageSize !== pagination.limit) {
          router.push(
            {
              query: {
                ...router.query,
                [`${prefix}pagination`]: qs.stringify(
                  {
                    page: 1,
                    limit: value.pageSize,
                  },
                  { allowDots: true, arrayFormat: 'brackets', encode: false },
                ),
              },
            },
            undefined,
            { shallow: true },
          )
        } else {
          router.push(
            {
              query: {
                ...router.query,
                [`${prefix}pagination`]: qs.stringify(
                  {
                    page: Number(value.pageIndex || 0) + 1,
                    limit: value.pageSize,
                    ...newCursors,
                  },
                  { allowDots: true, arrayFormat: 'brackets', encode: false },
                ),
              },
            },
            undefined,
            { shallow: true },
          )
        }
      } else {
        router.push(
          {
            query: {
              ...router.query,
              [`${prefix}pagination`]: qs.stringify(
                {
                  page: Number(value.pageIndex || 0) + 1,
                  limit: value.pageSize,
                },
                { allowDots: true, arrayFormat: 'brackets', encode: false },
              ),
            },
          },
          undefined,
          { shallow: true },
        )
      }
    } else {
      onTableStateChange?.({
        ...tableState,
        [`${prefix}pagination`]: {
          page: Number(value.pageIndex || 0) + 1,
          limit: value.pageSize,
        },
      })
    }
  }

  const onSortingChange = (updater: Updater<SortingState>) => {
    const value = functionalUpdate(
      updater,
      sorting?.map((s) => ({
        id: s.column,
        desc: s.arrange === 'desc',
      })),
    )

    if (!tableState) {
      router.push(
        {
          query: {
            ...router.query,
            [`${prefix}pagination`]: qs.stringify(
              { page: 1, limit: Number(pagination.limit || defaultPageSize) },
              { allowDots: true, arrayFormat: 'brackets', encode: false },
            ),
            [`${prefix}sorting`]: qs.stringify(
              value.map((v) => ({
                column: v.id,
                arrange: v.desc ? 'desc' : 'asc',
              })),
              { allowDots: true, arrayFormat: 'brackets', encode: false },
            ),
          },
        },
        undefined,
        { shallow: true },
      )
    } else {
      onTableStateChange?.({
        ...tableState,
        [`${prefix}pagination`]: {
          page: 1,
          limit: Number(pagination.limit || defaultPageSize),
        },
        [`${prefix}sorting`]: value.map((v) => ({
          column: v.id,
          arrange: v.desc ? 'desc' : 'asc',
        })),
      })
    }
  }

  const onGlobalFilterChange = (updater: Updater<string>) => {
    if (!router.isReady) return

    const value = functionalUpdate(updater, globalSearch)

    if (!tableState) {
      const query = { ...router.query, [`${prefix}search`]: value }
      if (!value) delete query[`${prefix}search`]
      router.push({ query }, undefined, { shallow: true })
    } else {
      onTableStateChange?.({
        ...tableState,
        search: value,
      })
    }
  }

  return {
    pagination: {
      pageIndex: (pagination.page || 1) - 1,
      pageSize: Number(pagination.limit || defaultPageSize),
    },
    onPaginationChange,
    sorting: sorting.map((s) => ({
      desc: s.arrange === 'desc',
      id: s.column,
    })),
    onSortingChange,
    globalFilter: globalSearch,
    onGlobalFilterChange,
  }
}
